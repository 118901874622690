<template>
  <div
    class="dropdown is-right has-text-left"
    :class="{ 'is-hoverable': itemsSelected.length }"
  >
    <span class="dropdown-trigger">
      <button class="button is-small is-info" :disabled="!itemsSelected.length">
        <span class="icon">
          <i class="fas fa-toggle-off" />
        </span>
        <span>Actions ({{ itemsSelected.length }})</span>
      </button>
      <div class="dropdown-menu has-arrow is-right">
        <div class="dropdown-content">
          <span class="dropdown-item" v-if="valuations.length">
            <span class="heading is-marginless has-text-grey">
              Valuation
            </span>
          </span>
          <a class="dropdown-item" v-if="hasCazanaValues">
            <AutoCheckBatchActionToggle
              label="Cazana"
              @toggle="$emit('toggleAction', $event, 'valuationCazana')"
            />
          </a>
          <div v-if="$experian">
            <div
              class="dropdown-divider"
              v-if="valuations.length && permissions.dataGuarantee"
            />
            <span class="dropdown-item" v-if="permissions.dataGuarantee">
              <span class="heading is-marginless has-text-grey">
                Guarantee term
              </span>
            </span>
            <span class="dropdown-item" v-if="permissions.dataGuarantee">
              <div class="field has-addons">
                <div class="control" v-for="term in dgTerms" :key="term">
                  <button
                    class="button is-small"
                    @click="$emit('toggleAction', term, 'dgTerm')"
                  >
                    {{ term }}
                  </button>
                </div>
              </div>
            </span>
            <div class="dropdown-divider" v-if="permissions.dataGuarantee" />
            <span class="dropdown-item" v-if="permissions.dataGuarantee">
              <span class="heading is-marginless has-text-grey">
                Guarantee Level
              </span>
            </span>
            <span class="dropdown-item" v-if="permissions.dataGuarantee">
              <div class="field has-addons">
                <div
                  class="control"
                  v-for="level in dgLevels"
                  :key="level.value"
                >
                  <button
                    class="button is-small"
                    @click="$emit('toggleAction', level.value, 'dgLevel')"
                  >
                    {{ level.label }}
                  </button>
                </div>
              </div>
            </span>
            <div
              class="dropdown-divider"
              v-if="
                (permissions.dataGuarantee || valuations.length) &&
                  permissions.mileageData
              "
            />
            <span class="dropdown-item" v-if="permissions.mileageData">
              <AutoCheckBatchActionToggle
                label="Mileage data"
                @toggle="$emit('toggleAction', $event, 'mileageData')"
              />
            </span>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckBatchActionDropdown',
  components: {
    AutoCheckBatchActionToggle: () => import('./AutoCheckBatchActionToggle')
  },
  props: {
    selectAll: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    },
    valuations: {
      type: Array,
      required: true
    },
    hasCazanaValues: {
      type: Number,
      required: true
    },
    dgLevels: {
      type: Array,
      required: true
    },
    dgTerms: {
      type: Array,
      required: true
    }
  },
  computed: {
    itemsSelected() {
      return this.selectAll.filter(value => value)
    }
  }
}
</script>
